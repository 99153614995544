.plboton{
    background: #EE811E;
    font-size: 0.8rem;
    font-weight: bold;
    border-color: #EE811E;
}


.plmedalla{
    width: 5rem;
    float: right;
    margin-right: -3.2rem;
}

.plicono{
    font-size: 1rem;
    margin-left: 1rem;
    color: #D8DAE2;
}
.coicono{
    font-size: 3rem;
    margin-left: 1rem;
    color: #D8DAE2;
}
.pcomparar{
    color:#D8DAE2;
    margin: auto;
    font-style: italic;
}

.pscomparar{
    color: #EE811E;
    margin: auto;
    font-style: italic;
}

.pdivcomparar{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 3rem;
    border: solid 1px #D8DAE2;
    cursor: pointer;
}

.psdivcomparar{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 3rem;
    border: solid 1px #EE811E ;
    cursor: pointer;
}

.plparrafo{
    font-size: 1.2rem;
}

.plparrafo2{
    font-size: 0.8rem;
    white-space: pre-line;
    text-align: left;
}

.pldetalle{
    background-color: red;
}

.plasterisco{
    font-size: 0.8rem;
}

.plvalor{
margin-bottom: 1rem;
font-size: 19px;
}

.pejecutivo{
    color:#EE811E;
    font-style: italic;
    font-weight: bold;
    margin-top: -0.8rem;
}

.srplanes{
	position: relative;
}

.srplanes::after{
    content: "";
	width: 100%;
	height: 12%;
    background: #f19801;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

.srnseguro{
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 2;

}

/* Estilo para el header de las cards de planes */



.tooltip{
    background-color: #D8DAE2 !important;
    color: black !important;
}

/* formato a la tabla de comparacion  */
.compasistencia{
    align-items: center;
    display: flex;
    justify-content: center;
}

.cpasistencia{
    font-weight: bold;
    margin-bottom: 4rem;
    color: #EE811E;
}

.ctr{
    background: #EE811E;
    color: white;
}


.cnombreseguro{
    height: 70px;
    text-align: center;
}


.cvalorprima{
    color: grey;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.cvalorseguro{
    color: #EE811E;
    margin-bottom: 0.2;
}

.icoinfo{
    font-size: 1rem;
    color: black;
}

.cdivicon{
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.cdivicoinfo{
    margin-left: 5rem;
    margin-top: -4rem;
}

.cpclinicas{
    color: #EE811E;
    cursor: pointer;
}

.btndescargar{
    background: #EE811E;
    color: white;
    width: 50%;
}

.btndescargar:hover{
    color: white;
    background: #EE811E;
}

.modal-backdrop.fade.show{
    opacity: 0.02;
}

.cpbtncerrar{
    cursor: pointer;
    color:#EE811E;
    height: 200px;
width: 50%;
}

.cpbtncerrar:hover{
    background: #f2f2f2;
    transition: .3s ease all;
    border-radius: 5px;
}

.rcorners1 {
  border-radius: 90px;
}

.rounded-top-left-1 {
    border-top-left-radius: 1rem;
}
.rounded-top-right-1 {
    border-top-right-radius: 1rem;
}
.rounded-bottom-left-1 {
    border-bottom-left-radius: 1rem;
}
.rounded-bottom-right-1 {
    border-bottom-right-radius: 1rem;
}

#sr .form-control, #sr #input-spinner-right-button, #sr #input-spinner-left-button,
#sr .btn.dropdown-toggle, #sr .srbtncotiza{
    padding-top: 9px;
    padding-bottom: 9px;
}

/* Estilos a pantallas pequeñas menores de 1397px*/


/* Estilos a pantallas pequeñas menores de 1315px*/


@media (max-width: 1315px) {
    .plbotones{
        margin-top: 2rem;
    }
}

/* Estilos a pantallas pequeñas menores de 1200px*/

@media (max-width: 1200px) {

    .plbotones{
        margin-top: 2rem;
    }
}

/* Estilos a pantallas pequeñas menores de 1024px*/

@media (max-width: 1024px) {
    .plbotones{
        margin-top: 4rem;
}
}

/* Estilos a pantallas pequeñas menores de 768px*/

@media (max-width: 768px) {
    .plbotones{
        margin-top: 0rem;
}

}


/* Estilos a pantallas entre 900px y 600px*/

@media (max-width: 900px) and (min-width: 600px)
{
    .cnombreseguro{
        height: 100%;
        padding: .7rem 0 .7rem;
    }

    .plbotones{
        margin-top: 2rem;
    }
}

/* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
    .cnombreseguro{
        height: 100%;
        padding: .7rem 0 .7rem;
    }

    .plbotones{
        margin-top: 2rem;
    }
    
}

