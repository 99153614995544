#ft,#ftlegal{
    background: #f2f2f2;
    /*height: 20rem;*/
    color: black;
    padding-bottom: 1rem; /* added */
}

#ftlegal .social_footer_ul
{
    padding-left: 1rem;
    padding-right: 1rem;
}

.footer{
    display: flex;
    margin-top: 1rem;
    margin-left: 10rem;
}

.fdivlogo {
    position: relative; 
  }
  
  .flogo1 {
    position: absolute; 
    width: 10rem;
    top: 0;
    left: 0;
  }
  
  .flogo2 {
    position: absolute; 
    width: 10rem;
    top:0;
    bottom: 0;
    right: 0;
  }



.fdivenlaces{
    margin-left: 4rem;
    line-height: .2; 
}

.fdivenlace{
    display: flex; 
    margin-top: 5rem;
    font-size: 12px;
    color: #0061a0;
}

.ficono{
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    font-size: 0.4rem;
    color: black;
}

.foparrafo{
    text-align: left;
    font-size: 0.7rem;
    margin-top: 1rem;
}

.fdivp1{
    display: flex;
}

.fdivp2{
    display: flex;
}

.fenlaces{
    text-decoration: none;
}

a.fenlaces{
    color:#0061a0;
}

.fdivinfo{
    font-size: 12px;
}

.fdivenlacesinternos{
    display: flex;
    margin-top: 3rem;
}


/* added */
@media (max-width: 767.98px){
    .fdivenlace {
        flex-direction: column;
    }
    .flogo{
        width: 7rem;
    }
    
}

/* Estilos a pantallas entre 900px y 600px*/

@media (max-width: 900px) and (min-width: 600px)
{
    .footer{
        margin-left: 0rem;
        display: inline;
    }  
    .fdivlogo{
        margin-top: 1rem;
        text-align: center;
    } 
    .fdivenlaces{
        margin-left: 0.5rem;
        line-height:inherit;
        margin-top: -3rem;
    }
    .flogo{
        width: 7rem;
    }
}


@media (max-width: 600px) {

.footer{
    margin-left: 0rem;
    display: inline;
}
.fdivlogo{
    margin-top: 1rem;
    text-align: center;
}

.fdivenlaces{
    margin-left: 0.5rem;
    line-height:inherit;
    margin-top: -3rem;
}

.fdivenlace{
    display: inline;
    font-size: 11px;
    line-height: 1.2;
}

.fdivp1{
    margin-top: 4rem;
}

.fdivinfo{
    font-size: 10px;
    line-height: 1.5;
    width: 95%;
}

.fdivenlacesinternos{
    font-size: 10.5px;
    margin-top:0;

}

.fdivtextolegal{
    font-size: 12.5px;
    
    text-align: center;

}


.ficono{
    margin-right: 0.1rem;
}

}

.flogo{
    width: 7rem;
}
