/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

#padreFormulario h1,#padreFormulario h6,#padreFormulario h4, #padreFormulario h5,#padreFormulario h2 {
    color: #EE811E;
    text-align: center;
}
#padreFormulario .text-left{
  text-align: left;
}
.mt-2{
  margin-top: 1em;
}
.ml-1{
  margin-left:0.5em;
}
.p-2{
  padding: 1em;
}
.p-3{
  padding: 0.5em 1em;
}
.lineHeight0{
  line-height: 2;
}
.MuiTypography-label, label{
  color:#EE811E;
}
#formularioPasajero.franja{
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border-bottom:"#fdf4e2 10px solid";
}

.MuiFormGroup-root label{
  color:black;
}
.wellGray{
  background-color: #F1F1F1;
  border-radius: 10px;
  padding: 10% 3%;
  min-height: 165px;
}
.text-right{
  text-align: right;
}
.wellGray2{
  background-color: #E7E6E6;
  margin-bottom: -4%;
  min-height: 100px;
}
.bg-gray-100{
  background-color: #E7E6E6;
}
.bg-gray-text{
  color: lightslategray;
}
.no-decorator{
  text-decoration: none;
}
.moneybig{
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  vertical-align: middle;
}
.logoWbe{
  width: 94px;
float: right;
position: relative;
}
.img-logo{
  width:20%;
}
.text-center{
  text-align: center;
}
.labelCodigoPais{
  background-color: gray;
  color: white;
  margin-right: 0;
  border-radius: 5px;
  border: 1px solid gray;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  
  width:8%;
  padding-top:17px;
  padding-bottom:17px;
  padding-left:2%; 
  padding-right:2%;
}
.strongLabel{
  font-weight: bolder;
  color:black;
}
.black{
  color:black;
}
.strong{
  font-weight: bold;
}

/*FOOTER CSS*/
#ft2,#ftlegal2{
  background: white;
  color: black;
  padding-bottom: 1rem; /* added */
}

#ftlegal2
{
  padding-left: 1rem;
  padding-right: 1rem;
}


.fdivlogo{
  margin-top: 1rem;
}

.fparrafo{
  font-size: 0.7rem;
  margin-top: -1rem;
  text-align: justify;
  color:dimgray;
  font-weight: 600;
}

.headerCardPasajero .MuiCardHeader-subheader{
  color:#EE811E;
  text-align: end;
  font-weight: 700;
}
.titular_center{
  background-color: #EE811E;
  color: white;
  text-align: center;
  padding: 4px 0;
}
.no_titular_center{
  background-color: gray;
  color: white;
  text-align: center;
  padding: 4px 0;
}
#resumenPrincipal .marginCOntenedor{
  margin-left: 8%;
}
/* .headerCardPasajero {
  text-align: center;
} */
/* Estilos a pantallas entre 900px y 600px*/
.celText{
  background:white;
  width:30%;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
}
.guionStyle{
  list-style-type: symbols(cyclic '-');
}
.plufPL{
  padding-left: 2vh;
}
.labelCodigoPaisForm{
  width:18%; 
}
.oculto{
  display: none;
}
.loader {
  width: clamp(5rem, 50vw, 10rem);
  overflow: hidden;
  animation: rotate 1s linear infinite;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--blue-1);
  position: relative;
}
.loader::before {
  z-index: var(--zx-400);
  position: absolute;
  content: "";
  inset: 10%;
  border-radius: inherit;
  background-color: var(--white-1);
}
.loader_status {
  position: absolute;
  inset: 0;
}
.loader_status::before {
  position: absolute;
  content: "";
  top: -50%;
  width: 100%;
  height: 100%;
  rotate: 45deg;
  background-color: var(--green-1);
}

#padreFormulario .MuiStepper-horizontal .Mui-completed .MuiStepLabel-labelContainer,
  #padreFormulario .MuiStepper-horizontal .Mui-disabled .MuiStepLabel-labelContainer{
    display:block;
  }
  #seccion_resumen .MuiGrid-item.left-align-responsive{
    text-align: center;
  }

  /* #padreFormulario input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
  } */
  .borderGrayBottom{
    border-bottom: lightgray 1px solid;
  }
  .cardMinHeight .MuiCard-root{
    min-height: 310px;
  }

@keyframes rotate {
  to {
    rotate: 360deg;
  }
}

@media (orientation: portrait)
{
  #padreFormulario .MuiStepper-horizontal .Mui-completed .MuiStepLabel-labelContainer,
  #padreFormulario .MuiStepper-horizontal .Mui-disabled .MuiStepLabel-labelContainer{
    display:none;
  }
  #seccion_resumen .MuiGrid-item.left-align-responsive{
    text-align: left;
  }
  #resumenPrincipal .marginCOntenedor{
    margin-left: 0;
  }
  .footer2{
      margin-left: 0rem;
      display: inline;
  }  
  .fdivlogo{
      margin-top: 1rem;
      text-align: center;
  }
  .wellGray2 {
    margin-bottom: -20%;
    min-height: 100px;
  }
  .img-logo{
    margin-top: 5%;
    width: 40%;
  }
  .labelCodigoPais{
    width: 18%;
  }
  .celText{
    width:50%;
  }
}
#boxPrice .card-title{
  color:black;
}
.wellGray h5{
  color:black;
}
.pluf{
  position: relative;
  top: -10px;
}
small{
  font-size: 0.7rem;
}
.noLink{
  text-decoration: none;
  color:white;
}
.noLink:hover{
  color:beige;
}

.buttonAsLink{
  background: none;
  border: none;
  color:darkblue;
  cursor: pointer;
  text-decoration: underline;
}
.buttonAsLink:hover{  
  color:#EE811E;
}


/* @media (orientation: landscape)
{
  .wellGray2 {
    margin-bottom: -10%;
    min-height: 100px;
  }
  .img-logo{
    margin-top: 1%;
    width: 30%;
  }
} */

/* 
@media (max-width: 600px) {
.footer2{
  margin-left: 0rem;
  display: inline;
}
.fdivlogo{
  margin-top: 1rem;
}
} */

