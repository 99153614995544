
#sr{
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
}

#cd{
    margin-bottom: 2rem;
}

#srenata{
    text-align: center;
}

#srimg{
    width: 8rem;
}

#rsubtitulo{
    text-align: center;
    color:#494949 ;
    font-weight: bold;
}

.srform{
    margin-top: 3rem;
    /* margin-right: 3rem; */
}

.srlabelpersonas{
    color: grey;
    padding-right:1rem ;
    font-size: 12px;
}

.srnpersonas{
    margin-left: 0rem;
}

.srlabel{
    color: grey;
    padding-right:1rem ;
    white-space: pre-wrap;
}

.srselect{
    margin-left: 0rem;
}

.srlabelselect{
    color: grey;
    white-space: pre-wrap;
    margin-left: 0rem;

}
  .srnedad{
      margin-left: -0.2rem;
      margin-right: -0.5rem;
  }


.btnvermas{
    color: #EE811E;
}

.btnvermas:hover{
    background-color: #EE811E;
}

.srapi{
    font-size: 0.7rem;

}


.fecha{

    font-size: 15px;
    border-color: #EE811E;
    border-radius: 5px;
    height: 39px;
    width: 155px;
    text-align: center;
    cursor: pointer;
    border-style:double;
}


.srlabelfecha{
    border: solid 1px;
    border-radius: 10px;
    border-color: #EE811E;
    cursor: pointer;
    margin-left: -0.5rem;
}

.srlbidavuelta{
    color: grey;
    white-space: pre-wrap;
    padding-right: 100px;
}

.srcolvuelta{
    margin-left: -3rem;
}


    .srlabelvalormensual{
        margin-left: 3.5rem;
    }
  .srnvalormensual{
      margin-left: 0rem;
  }

.dropdown.hk--custom--select.primary{
    border-style: groove;
}

.hk--custom--select .dropdown-menu{
    border-color: #EE811E;
}

.col-lg-9{
    margin-left: -2rem;
}


input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

.dropdown-item.active{
        background-color:white;
        color: black;
}

.dropdown-item.selected{
    background-color:white;
    color: black;
}

.dropdown-item:hover{
    background-color:#EE811E;
    color: black;
}


button.btn.dropdown-toggle.btn-primary.bs-placeholder{
    background: white;
    color: black;
    border-color: #EE811E;
}

button.btn.dropdown-toggle.btn-primary.bs-placeholder:hover{
    color: black;
}

button.btn.dropdown-toggle.btn-primary{
    background: white;
    border-color: #EE811E;
    color:black;
}

.srbtncotiza{
    color: white;
    background: #EE811E;
    border-color:#EE811E;
    margin-left: 0rem;
    width: 12rem;
}

.srbtncotiza:hover{
    color: white;
    background-color: #EE811E;
    font-weight: bold;
}

.srbtncotiza:focus{
    background-color: #EE811E;
    color: white;
}

.btn-outline-primary:hover{
    color: white;
}

button#input-spinner-left-button{
    background: #EE811E;
    border-color: #EE811E;
    color: white;
}

button#input-spinner-right-button{
    background: #EE811E;
    border-color: #EE811E;
    color: white;
}

.swal-button{
    background-color: #EE811E;
}


 /* .dropdown-menu.show{
    text-align: center;
}  */

button.btn.actions-btn.bs-select-all.btn-primary.bi.bi-check-square{
    background-color: white;
    border-color: white;
    margin-top: -1rem;
    height: 0.1px;
}

button.btn.actions-btn.bs-deselect-all.btn-primary.bi.bi-square{
    background-color:white;
    border-color: white;
    margin-top: -1rem;
    height: 0.1px;
}

.bs-box.bs-actionsbox{
    padding: 0;
}

button.btn.actions-btn.bs-deselect-all.btn-primary.bi.bi-square::before{
    color: white;
}


button.btn.actions-btn.bs-select-all.btn-primary.bi.bi-check-square::before{
    color: white;
}

input.metCheck.form-check-input.btn.actions-btn.btn-primary{
    background-color: #EE811E;
    border-color: white;
}

 .bccardtitulo1{
    background-color: #EE811E;
    color: white;
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    cursor: pointer;
}
.bctitulos{
    padding: 15px;
}

.bclu{
    list-style-position:inside;
    margin-left: 2rem;
}


.srcolplan{
    margin-right: 2rem;
}

.srcolselect{
    margin-left: -3rem;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    text-align: center;
}




/* added */
#sr table td{
    vertical-align: middle;
}





/* Estilos a pantallas pequeñas menores de 1400px*/

@media (max-width: 1400px) {

  .srselect{
      margin-left: -1rem;
  }

  .srlabelselect{
      color: grey;
      white-space: pre-wrap;
      margin-left: -1rem;

  }


  .srbtncotiza{
      color: white;
      background: #EE811E;
      border-color:#EE811E;
      margin-left: 1rem;
      width: 12rem;
  }

.srlbidavuelta{
    padding-right: 10px;
}

.srcolvuelta{
    margin-left: -1.5rem;
}

.srcolselect{
    margin-left: 0rem;
}


}



/* Estilos a pantallas pequeñas menores de 1200px*/

@media (max-width: 1200px) {

  .srbtncotiza{
      margin-left: 0.5rem;
      width: 9rem;
  }


      .srlabelvalormensual{
          margin-left: 2rem;
      }
      .srnvalormensual{
          margin-left: -2rem;
      }

    .srcolvuelta{
        margin-left: -1.5rem;
    }

    .srcolselect{
        margin-left: 0rem;
    }

}


@media (max-width: 1196px) {

  
      .srcolvuelta{
          margin-left: 0.5rem;
      }

      .srcolida{
        margin-left: -1rem;
      }
  
      .srcolvuelta{
        margin-right: 1rem;
    }

      .srcolselect{
          margin-left: 0.5rem;
      }

      .srbtncotiza{
        width: 200px;
      }
  
  }



/* Estilos a pantallas pequeñas menores de 1024px*/

@media (max-width: 1025px) {



  .srnpersonas{
      margin-left: -3rem;
  }

  .srnedad{
      margin-left: -2rem;
  }

 .srlbedadmayor{
    margin-left: -2rem;
 }

 .srlbnpersonas{
    margin-left: -3rem;
 }


    .srbtncotiza{
        margin-left: 4.5rem;
        width: 9rem;
    }


    .srcolvuelta{
        margin-left: 0;
        margin-right: 9rem;
        width: 5px;
    }

    .srcolida{
        margin-left: -2.5rem;
        margin-right: 9rem;
        width: 5px;
    }

    .srcolselect{
        margin-left: 1rem;
        margin-right: -1.5rem;
    }




}


/* Estilos a pantallas pequeñas menores de 992px*/
/* 990 */
@media (max-width: 992px) {

    .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
        text-align: center;
    }
    .hk--custom--select .dropdown-toggle .filter-option-inner-inner{
        text-align: center;
    }

     .txfida{
        width: 130px !important;
     }

     .txfvuelta{
        width: 130px !important;
     }

      .srselect{
          margin-left: -1.8rem;
      }


        .srbtncotiza{
            margin-left: 0rem;
            width: 6rem;
        }

        .srform{
            margin-left: 2rem;
        }


        .srcolvuelta{
            margin-left: -2rem;
        }

        .srcolselect{
            margin-left: -0.4rem;
            margin-right: 3rem;
        }
}

@media (max-width: 989px) {
    .srnpersonas{
        margin-left: 0rem;
    }

    .srnedad{
        margin-left: 0rem;
    }

    .srlbnpersonas{
        margin-left: 0rem;
    }

    .srlbedadmayor{
        margin-left: 0rem;
    }

    .srcolselect{
        text-align: center;
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .srbtnverplanes{
        justify-content: center;
        margin-left: 0rem;
        margin-right: 0rem;
    }

        
    }


@media (max-width: 913px) {

 .srcolida{
    width: 100% !important;
    margin-left: 0rem;
    margin-right: 0rem;
 }

 .txfida{
    width: 100% !important;
    
 }

 .srcolvuelta{
    width: 100% !important;
    margin-left: 0rem;
    margin-right: 0rem;
 }

 .txfvuelta{
    width: 100% !important;

 }

}



/* Estilos a pantallas pequeñas menores de 900px*/

@media (max-width: 900px) {

  .srlabelselect{
      color: grey;
      white-space: pre-wrap;
      margin-left: 0.5rem;
  }

    .srselect{
        margin-left: 0.5rem;
    }


    .srbtncotiza{
        margin-left: 5.5rem;
        width: 9rem;
    }

    .srnpersonas{
        margin-left: 0rem;
    }
    .srnedad{
          margin-left: 0rem;
    }

    .srcolvuelta{
        margin-left: 0rem;
    }

    .srcolselect{
        margin-left: 0rem;
    }


}

@media (max-width: 821px) {

    .srcolida{
        text-align: center;
      }

      .srnpersonas{
        margin-left: 0rem;
    }
  
    .srnedad{
        margin-left: 0rem;
    }
  
   .srlbedadmayor{
      margin-left: 0rem;
   }
  
   .srlbnpersonas{
      margin-left: 0rem;
   }

      .srcolvuelta{
        text-align: center;
      }
      .srcolselect{
          text-align: center;
      }
      .srbtnverplanes{
          justify-content: center;
      }

}


/* Estilos a pantallas pequeñas menores de 768px*/

@media (max-width: 768px) {
.srform{
    margin-left: 0rem;
}

}


  /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
.srbtncotiza{
    margin-left: 5rem;
}

.srform{
    margin-left: 0rem;
    margin-top: 0rem;
}

.srselect{
    margin-left: 0;
}

.srlabelselect{
    margin-left: 0;
}

.srnpersonas{
    margin-left: 0;
}

.srnedad{
      margin-left: 0rem;
}

.srlabelvalormensual{
    margin-left: 0rem;
}

.srnvalormensual{
    margin-left: 0;
}

.srcolvuelta{
    margin-left: 0rem;
}

.srcolselect{
    margin-left: 0rem;
}

}


@media (max-width: 415px) {

    .srnpersonas{
        margin-left: 0rem;
    }
  
    .srnedad{
        margin-left: 0rem;
    }
  
   .srlbedadmayor{
      margin-left: 0rem;
   }
  
   .srlbnpersonas{
      margin-left: 0rem;
   }

    .srcolvuelta{
        margin-right: 0rem;
        margin-left: 0rem;
    }

    .srcolida{
      text-align: center;

    }

    .srcolselect{
        text-align: center;
    }
    .srbtnverplanes{
        justify-content: center;
    }
}

@media (max-width: 450px) {
    #srtooltip2{
        left: 10px !important;
      }
}
@media (max-width: 394px) {

    .srcolida{
      text-align: center;
    }

    .srcolselect{
        text-align: center;
    }
    .srbtnverplanes{
        justify-content: center;
    }
}


@media (max-width: 391px) {

    .srcolida{
      text-align: center;
    }
    .srcolvuelta{
        margin-right: 0rem;
        margin-left: 0rem;
    }
    .srcolselect{
        text-align: center;
    }
    .srbtnverplanes{
        justify-content: center;
    }
}


@media (max-width: 376px) {

    .srcolida{
      text-align: center;
    }

    .srnpersonas{
        margin-left: 0rem;
    }
  
    .srnedad{
        margin-left: 0rem;
    }
  
   .srlbedadmayor{
      margin-left: 0rem;
   }
  
   .srlbnpersonas{
      margin-left: 0rem;
   }

   .srcolvuelta{
    margin-right: 0rem;
    margin-left: 0rem;
}

    .srcolselect{
        text-align: center;
    }
    .srbtnverplanes{
        justify-content: center;
    }
}

@media (max-width: 361px) {

    .srcolida{
      text-align: center;
    }
    .srcolselect{
        text-align: center;
    }
    .srbtnverplanes{
        justify-content: center;
    }
}

@media (max-width: 281px) {

    .srcolida{
      text-align: center;
      margin-right: 0rem;
      margin-left: 0rem;
    }

    .srcolvuelta{
        margin-left: 0rem;
      }

    .srcolselect{
        margin-right: 1.5rem;
    }
    .srbtnverplanes{
        justify-content: center;
    }
}
