
.logo {
    height: auto;
    max-height: 38px;
    width: auto;
    margin: 16px auto;
}
nav {
    z-index: 996;
    width: 100%;
    background-color: #f19801;
}
#inicio{
    background-image: url(../imagenes/Home/banner.jpg);
    width: inherit;
    height: 60vh;
    background-position: top;
    background-size:cover;
    background-repeat: no-repeat;
    /* background-color: #f19801; */
}

.inav{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0;
    background-color: #f19801;
    max-width: 1300px;
    margin: auto;
    max-height: 180px;
}


#ilogo{
    width: 12rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 4rem;
}


.navbar-brand {
    margin-right: 15rem;
}


.navbar-light .navbar-nav .nav-link {
    display: inline-block;
    background-color: transparent;
    position: relative;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 40px;
    text-transform: uppercase;
}

.navbar-light:hover .navbar-nav:hover .nav-link:hover {
    background-color: #f19801;
    -webkit-box-shadow: inset 0px 0px 0px 2px #fff;
    -moz-box-shadow: inset 0px 0px 0px 2px #fff;
    box-shadow: inset 0px 0px 0px 2px #fff;
    text-decoration: underline;
    color: #fff;
}


.navbar-light .navbar-nav .nav-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 40px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}


/*.navbar-light .navbar-nav .nav-link{
    color:#000000;
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-top: 1.2rem;
}


.navbar-light .navbar-nav .nav-link{
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-decoration: none;
    color: #333;
    }
.navbar-light .navbar-nav .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: #f19801;
    transition: left .8s;
    }
.navbar-light .navbar-nav .nav-link:hover:after {
    left: 0;
    }*/


#barraenlace{
    background:#ffffff;
}

#parrafo{
    justify-content: left;
    height: 55vh;
    overflow: hidden;
}

#parrafo > div{ align-self: center !important; }
#inparrafo
{
    background-color: rgba(238,129,30,.55);
    margin-left: 4%;
    width: 40rem;
}

#ip1{
    font-size: 2.3rem;
    color: white;
    font-weight: bold;
    /*width: 40rem;
    margin-left: -6rem;*/
}

.incontenedor{
margin: 2rem;
}

.ip1texto{
  /*  color:#a4ce4e; */
  color:#ffc913
}

#ip2{
    color: #f19801;
    font-size: 3.2rem;
    font-weight: bold;
   /* width: 40rem;
    margin-left: -6rem;*/
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#ip3{
    color:white;
    /* font-weight: bold; */
    margin-bottom: 1rem;
    margin-top: 2rem;
    /*margin-left: -11rem;*/
}

.itarjetas{
    width: 35rem;
    /*width: 26rem;*/
    margin-top: 0rem;
    /*margin-left: -11rem;*/
}


.botonInicio{
    color: white;
    background: #a4ce4e;
    border-color:#a4ce4e;
    margin: 2rem 0;
    font-weight: bold;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;
    /*margin-left: -11rem;*/
}

.botonInicio:hover{
    background: white;
    border-left: white;
    border-right: white;
    border-top: white;
    color: #a4ce4e;
}

.botonInicio:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: #a4ce4e;
    transition: left .8s;
    }

.botonInicio:hover:after {
    left: 0;
    }

/* Estilo para capsula amarilla */
  .inline-block {
      display: inline-block;
  }

  .bg-brand-yellow {
      --tw-bg-opacity: 1;
      background-color: rgba(255,201,19,var(--tw-bg-opacity));
  }
.capsula{
    border-radius: 75px;
    font-weight: 700;
    line-height: 1;
    border-radius: 3.25rem;
    /*white-space: nowrap;*/
    padding: 0.20em 0.30em
  }

  .bg-brand-grey {
      --tw-bg-opacity: 1;
      background-color: rgba(224,233,242,var(--tw-bg-opacity));
  }
  .capsulaTarjeta{
      border-radius: 75px;
      font-weight: 200;
      line-height: 0;
      border-radius: 3.25rem;
      border: 2.5px solid #4AA3F2;
      background: #e0e9f2 ;
      padding: 1em 0.35em;
    }


    
    @media (max-width: 991.98px){
        #parrafo
        {
            height:95vh;
        }
        #inicio{
            background-image: url(../imagenes/Home/bannerPhone.jpg);
            background-position: top;
            background-size: cover;
            height: 80vh;
        }
        #inparrafo
        {
            background-color: #f19801;
            width: 100%;
            margin-left: 0%;
            margin-top: 8% !important;
            opacity: 1;
        }
        .navbar-brand{ margin-right: 0;}
        #barraenlace{
            min-height: 30px;
        }
    }

    /* @media (min-width: 992px) and (max-width: 1199.98px) { 
        #ip1{
            font-size: 1.7rem;
        }
        #ip2{
            font-size: 2.2rem;
        }
        .itarjetas{            
            width: 26rem;            
        }
        .navbar-light .navbar-nav .nav-link{ font-size: 0.6rem; }
        
    } */

    @media (max-width: 913px) and (max-height: 1369px){ 

        #inparrafo{
            margin-top: -1rem !important;
         }

         #inicio{
            margin-bottom: 0rem;
            background-position: top;
            background-size: contain;
            height: 80vh;
        }

        }
    
    @media (max-width: 900px){
        #inicio{
            height: 65vh;
        }
    }

    @media (max-width: 844px){
        #inicio{
            height: 72vh;
        }
    }
   
    /* @media (min-width: 1200px) and (max-width: 1299.98px) {
        #ip2{
            font-size: 2.8rem;
        }
    } */

    @media (max-width: 821px){ 
        #inicio{
            margin-bottom: -4rem;
        }

        
    }

    @media (max-width: 541px){ 


        #inicio{
            margin-bottom: 2rem;
         }
        
    }

    @media (max-width: 415px){ 

        #inparrafo{
           margin-top: -7rem !important;
        }


        #inicio{
            margin-bottom: -8rem;
         }
        
    }

    @media (max-width: 391px){
        #barraenlace{
            min-height: 30px;
        }

        #inparrafo{
            margin-bottom: -5rem;
         }
        
         #inicio{
            margin-bottom: -2rem;
         }
    }

    @media (max-width: 376px) and (max-height: 668px){

        #inparrafo{
            margin-top: 4rem !important;
         }

         #inicio{
            margin-bottom: 8rem;
         }
    }

    @media (max-width: 361px){

        #inparrafo{
            margin-top: -4rem !important;
         }

          #inicio{
            margin-bottom: -1rem;
         } 
    }


    @media (max-width: 340px){
        #barraenlace{
            min-height: 50px;
        }
        
    }


    
    @media (max-width: 281px){
        .incontenedor{
            margin-left: -0.1rem;
        }
        #inicio{
            margin-bottom: 4rem;
        }
        
    }

    /* 

    @media (max-width: 413px){ 

        #inparrafo{
           height: 52%;
        }

    }


    } */

    
