
/* Added */

.bctitulos{
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;  
}


.bcparrafo3{
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 2rem;
}



#bccardtitulo1{
    width: 19rem;
    text-align: center;
    color: white;
    height: 100%;
    background-color:#f19801;
}


#bccardtitulo1:hover{
    background: #f19801;
    cursor: pointer;
    color: white;
}

#bccardtitulo1:focus{
    background: #f19801;
    cursor: pointer;
    color: white;
}

.bcgeneral{
    display: flex;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-color:  #E0E0E0;
    border-radius:10px;
    padding-top: 2rem;
    padding-bottom: 2rem;
}


.bccol1{
    display: flex;
}

.bccol2{
    display: flex;
}

.bcimge{
    width: 6rem;
}

.bcpa{
    height: 5rem;
    text-align: center;
}

.bchi{
    text-align: center;
    margin-top: 1rem;
}

.bcprincipal{
    margin: 1rem;
}


/* Estilos a pantallas pequeñas menores de 1240px*/

@media (max-width: 600px) {

.bcgeneral{
    display: grid;
    margin-right: 2rem;
    margin-left: 1rem;
}

.bcimge{
    width: 4rem;
}

.bcdescripc{
    font-size: 9px;
}

.bchi{
    margin-top: -1rem;
}

.bccol2{
    margin-top: 1rem;
}


}

@media (max-width: 281px) {

.bcimge{
    
}

.bcdescripc{
    font-size: 7px;
}
    
.bcprincipal{
    margin: 0;
}
    
    }
    
