
.titulos{
  text-align: center;
  font-weight: bold;
  margin-left: 2rem;
  margin-right: 2rem;

}

.subtitulos{
  text-align: center;
  color: #9A9A9A;
}

.btn:focus{
  box-shadow:none !important;
}


.Uf{
  list-style: none;
}




